import React, { useEffect, useState } from 'react';
import Login from "../components/app/containers/Login";
import Dashboard, {LoaderProducts} from "../components/app/containers/Dashboard";
declare module 'next-auth/client';
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/client';

import { getCookie } from 'cookies-next';
import Layout from '../components/app/global/Layout';
import DialogDuplicate from "../components/app/global/dialogs/DialogDuplicate";
import DialogDelete from "../components/app/global/dialogs/DialogDelete";
import DialogMove from "../components/app/global/dialogs/DialogMove";
import DialogRename from "../components/app/global/dialogs/DialogRename";
import DialogAlert from "../components/app/global/dialogs/DialogAlert";
import Head from 'next/head';

export default function Index () {
    const [ session, loading ] = useSession();


    const router = useRouter();

    if (typeof window !== 'undefined' && loading) return null

    const [agreementAccepted, setAgreementAccepted] = useState(false);
    const [haveTeam, setHaveTeam] = useState(false);

    const [indexTeamInited, changeIndexTeamInited] = useState(false);
    const [teamCredits, setTeamCredits] = useState(0);
    useEffect(() => {


        if(!indexTeamInited){
            if(session){

                changeIndexTeamInited(true);

                if( getCookie('usrA') === undefined || getCookie('usrA') === null  || getCookie('usrA') === "" ){
                    router.push('/terms-and-conditions');
                 //   setUserTeamLoading(false);
                } else {

                    if(
                        (getCookie('userTeam') === undefined || getCookie('userTeam') === null  ||getCookie('userTeam') === "") &&
                        (getCookie('usrA') === true)

                    ){
                        router.push('/team-select');
                    } else {
                        setUserTeamLoading(false);
                    }

                }

            } else {
                setUserTeamLoading(false);
            }
        }
    }, []);


    const [loadingShowTop, setLoadingShowTop] = useState(false);
    const [loadingTransitionShowTop, setLoadingTransitionShowTop] = useState(false);


    const changeLoadingShowTop = (variable) => {

        if(variable === true){
            setLoadingShowTop(variable);
            setTimeout(function() {
                setLoadingTransitionShowTop(true);
            },1);




        } else{


            setLoadingTransitionShowTop(false);
                setTimeout(function() {
                    setLoadingShowTop(variable);
                },500);



        }

    };


    const [userTeamLoading, setUserTeamLoading] = useState(true);


    const [duplicateProductID, setDuplicateProductID] = useState(null);

    const [duplicateProductVersions, setDuplicateProductVersions] = useState(null);
    const [duplicateProductName, setDuplicateProductName] = useState(null);

    const openDuplicateDialogAndSetIDZ = (productID, productVersions, productName) => {

        setDuplicateProductVersions(productVersions);
        setDuplicateProductName(productName)

        setDuplicateProductID(productID);
        handleClickDuplicate();
    };
    let handleClickDuplicate = () => {

    };


    const [deleteProductID, setDeleteProductID] = useState(null);
    const openDeleteDialogAndSetIDZ = (masterProductID, productID) => {

        setDeleteProductID(productID);
        handleClickDelete();
    };
    const [deleteProductIDMultiple, setDeleteProductIDMultiple] = useState(null);
    const openDeleteDialogAndSetIDZMultiple = (selectedProducts) => {

         setDeleteProductIDMultiple(selectedProducts);
         handleClickDeleteMultiple();
    };
    let handleClickDeleteMultiple = () => {

    };

    let handleClickDelete = () => {

    };




    const [renameProductID, setRenameProductID] = useState(null);
    const [renameProductName, setRenameProductName] = useState(null);

    const openRenameDialogAndSetIDZ = (productID,productName) => {

        setRenameProductID(productID);
        setRenameProductName(productName);
        handleClickRename();
    };

    let handleClickRename = () => {

    };

    const [moveProductID, setMoveProductID] = useState(null);
    const openMoveDialogAndSetIDZ = (productID) => {

        setMoveProductID(productID);
        handleClickMove();
    };

    let handleClickMove = () => {

    };

    const reloadProducts = () => {
        handleClickReload();
    };
    let handleClickReload = () => {

    };

    const [warningMessage, setWarningMessage] = useState("");
    let triggerWarning = (warningMessage) => {
        changeLoadingShowTop(false);
        setWarningMessage(warningMessage);
        handleTriggerWarning();
    };

    let handleTriggerWarning = () => {

    };



    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        setIsClient(true);

        if(isClient){
            if(
                (getCookie('userTeam') !== undefined || getCookie('userTeam') !== null  ||getCookie('userTeam') !== "")

            ){
               setHaveTeam(true);
            }

            if(getCookie('usrA') === true){
                setAgreementAccepted(true);
            }
        }

    }, [isClient]);


    return (
        //onLoginClick


        <>

            <Head>
                <title>Obalero | Products</title>

                <meta name="description" content="OBALERO - your best packaging friend." />
                <meta name="keywords" content="OBALERO, Packaging, Product design" />
                <meta property="og:title" content="OBALERO" />
                <meta property="og:description" content="OBALERO - your best packaging friend." />
                <meta property="og:site_name" content="OBALERO" />

            </Head>

            {loading ||  userTeamLoading || !isClient ?
              null
                :


            <Layout
                teamCredits={teamCredits}
                setTeamCredits={setTeamCredits}
                showMainMenu={session? true   : false } sideMenuPage={"products"}
                isLogin={session? false   : true } showSideMenu={false}>
                {session && agreementAccepted && haveTeam ?
                    <>
                        <Dashboard
                            changeLoadingShowTop={changeLoadingShowTop}
                            openDuplicateDialogAndSetIDZ={openDuplicateDialogAndSetIDZ}
                            openDeleteDialogAndSetIDZ={openDeleteDialogAndSetIDZ}
                            openMoveDialogAndSetIDZ={openMoveDialogAndSetIDZ}
                            openRenameDialogAndSetIDZ={openRenameDialogAndSetIDZ}
                            openDeleteDialogAndSetIDZMultiple={openDeleteDialogAndSetIDZMultiple}
                            toggleReload={ (toggleReload: () => void) => {
                                handleClickReload = toggleReload;
                            } }

                        />
                        <DialogDuplicate
                            duplicateProductID={duplicateProductID}
                            duplicateProductVersions={duplicateProductVersions}
                            toggleDuplicate={ (toggleDuplicate: () => void) => {
                                handleClickDuplicate = toggleDuplicate;
                            } }
                            currentProductName={duplicateProductName}
                            changeLoadingShow={changeLoadingShowTop}
                            reloadProducts={reloadProducts}
                        />
                        <DialogDelete
                            productDeleteUUID={deleteProductID}
                            toggleDelete={ (toggleDelete: () => void) => {
                                handleClickDelete = toggleDelete;
                            } }
                            toggleDeleteMultiple={ (toggleDeleteMultiple: () => void) => {
                                handleClickDeleteMultiple = toggleDeleteMultiple;
                            } }
                            triggerWarning={triggerWarning}
                            deleteProductIDMultiple={deleteProductIDMultiple}
                            handleClickDeleteMultiple={handleClickDeleteMultiple}
                            changeLoadingShow={changeLoadingShowTop}
                            reloadProducts={reloadProducts}
                        />




                        <DialogAlert
                            toggleAlert={ (toggleAlert: () => void) => {
                                handleTriggerWarning = toggleAlert;
                            } }
                            alertType={"global-error.tsx"}
                            message={warningMessage}
                        />


                        <DialogMove
                            productMoveUUID={moveProductID}
                            toggleMove={ (toggleMove: () => void) => {
                                handleClickMove = toggleMove;
                            } }
                            changeLoadingShow={changeLoadingShowTop}
                            reloadProducts={reloadProducts}
                        />
                        <DialogRename
                            productRenameUUID={renameProductID}
                            toggleRename={ (toggleRename: () => void) => {
                                handleClickRename = toggleRename;
                            } }
                            renameProductName={renameProductName}
                            changeLoadingShow={changeLoadingShowTop}
                            reloadProducts={reloadProducts}
                        />
                            <LoaderProducts className={`main-loader ${loadingTransitionShowTop ? 'showTransition' : 'hideTransition'} ${!loadingShowTop ? 'hiddeComplete' : ''} `}
                            >

                                <img src={'/logoAnimate.svg'} />
                            </LoaderProducts>


                    </>
                    :    <Login/>
                }


            </Layout>
            }
        </>

    )
}

